import {BrowserModule, BrowserTransferStateModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {HeaderComponent} from './layout/common/header/header.component';
import {FooterComponent} from './layout/common/footer/footer.component';
import {HomeComponent} from './layout/pages/home/home.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {MatAutocompleteModule, MatDialogModule} from '@angular/material';
import {MainInterceptor} from './interceptors/main.interceptor';
import {TextMaskModule} from 'angular2-text-mask';
import {CookieService} from 'ngx-cookie-service';
import {HowItWorksDialogComponent} from './layout/modal/how-it-works-dialog/how-it-works-dialog.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {CallMeDialogComponent} from './layout/modal/call-me-dialog/call-me-dialog.component';
import {AppStorage} from '../storage/universal.inject';
import {CookieStorage} from '../storage/browser.storage';
import {DataService} from '../shared/shared-data.service';
import {WriteReviewDialogComponent} from './layout/modal/write-review-dialog/write-review-dialog.component';
import {HomeService} from './layout/pages/home/home.service';
import {ClearIconDirective} from './directives/clear-icon.directive';
import {TransferHttpCacheModule} from '../modules/transfer-http/transfer-http.module';
import {ConstantService} from './services/constant.service';
import {TagService} from './services/tag.service';
import {NgxPhoneSelectModule} from '../modules/ngx-phone-select/ngx-phone-select.module';
import {TrackingService} from './services/tracking.service';
import {DeviceDetectorModule} from 'ngx-device-detector';
import {LazyLoadImageModule} from 'ng-lazyload-image';
import {GeneralModule} from '../modules/general/general.module';
import {DeferLoadModule} from '@trademe/ng-defer-load';
import {BeforeCloseDialogComponent} from './layout/modal/before-close-dialog/before-close-dialog.component';
import {AttentionComponent} from './layout/common/attention/attention.component';
import {PolicyPopupComponent} from './layout/common/policy-popup/policy-popup.component';
import {CardDialogComponent} from './layout/modal/card-dialog/card-dialog.component';
import {
  GetQuoteCompleteDialogComponent
} from "./layout/modal/get-quote-complete-dialog/get-quote-complete-dialog.component";
import { MatButtonModule } from "@angular/material/button";

/* Raven
  .config('https://fc1521c172de4ecc9fb0a5d778044461@sentry.io/224808')
  .install();

export class RavenErrorHandler implements ErrorHandler {
  constructor(@Inject(PLATFORM_ID) private platformId) {
  }

  handleError(err: any): void {
    if (isPlatformServer(this.platformId)) {
      console.warn('[RAVEN] Not a browser!');
      console.warn(err);
      return;
    }
    Raven.captureException(err);
  }
} */

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HowItWorksDialogComponent,
    CallMeDialogComponent,
    WriteReviewDialogComponent,
    HomeComponent,
    BeforeCloseDialogComponent,
      AttentionComponent,
      PolicyPopupComponent,
    CardDialogComponent,
    GetQuoteCompleteDialogComponent
  ],
  imports: [
      LazyLoadImageModule,
    NgbModule,
    GeneralModule,
    FormsModule,
    NgxPhoneSelectModule,
    ReactiveFormsModule,
    BrowserModule.withServerTransition({appId: 'serverApp'}),
    AppRoutingModule,
    /*ServiceWorkerModule.register('./ngsw-worker.js', {enabled: environment.production}),*/
    HttpClientModule,
    MatAutocompleteModule,
    TextMaskModule,
    MatDialogModule,
    BrowserAnimationsModule,
    BrowserTransferStateModule,
    TransferHttpCacheModule,
    DeviceDetectorModule.forRoot(),
    DeferLoadModule,
    MatButtonModule
  ],
  providers: [
    /* {provide: ErrorHandler, useClass: RavenErrorHandler}, */
    CookieService,
    {provide: HTTP_INTERCEPTORS, useClass: MainInterceptor, multi: true},
    {provide: 'isBrowser', useValue: true},
    {provide: AppStorage, useClass: CookieStorage},
    DataService,
    HomeService,
    ConstantService,
    TagService,
    TrackingService
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    HowItWorksDialogComponent,
    CallMeDialogComponent,
    WriteReviewDialogComponent,
    BeforeCloseDialogComponent,
    CardDialogComponent,
    GetQuoteCompleteDialogComponent
  ]
})
export class AppModule {
}
