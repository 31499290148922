<div aria-hidden="true" class="modal show" role="dialog" style="display: block;" tabindex="-1">
    <div class="modal-dialog" role="document">
        <div class="modal-content">

            <div class="modal-header">
                <button *ngIf="!isMultiCity" type="button" class="btn-back" (click)="closeDialog(false, true)">
                    <i class="icon-back"></i>
                </button>
                <h2 class="modal-title">Last step!</h2>
                <h2 *ngIf="!isMultiCity" class="modal-subtitle">3 of 3</h2>
                <button (click)="closeDialog()" aria-label="Close" class="close" data-dismiss="modal" type="button">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <div class="modal-body">
                <form [formGroup]="personalDataForm">
                  <div *ngIf="!isCorrectFlight" id="errorBox" class="col-xs-12 pn errorBox container">
                    <div class="alert" role="alert">
                      We don’t offer flights to this destination or economy tickets.
                    </div>
                  </div>
                    <div class="call-manager">
                        <img src="./assets/img/call-manager.png" alt="">
                    </div>
                    <h2 class="get-quote-title">GET YOUR FREE QUOTE</h2>
                    <span class="list-title">Complete the form and get the lowest fares asap! </span>
                    <div [ngClass]="{
                        'has-error-mobile':
                        personalDataForm.get('firstName').invalid && !personalDataValid
                    }" class="form-input">
                        <label for="name">Name</label>
                        <input [(ngModel)]="contactData.firstName"
                               [readOnly]="formSending"
                               appClearIcon
                               class="form-control"
                               formControlName="firstName"
                               id="name"
                               placeholder="Enter your name"
                               type="text">
                        <span [class.d-none]="formSending" class="xIcon"></span>
                    </div>

                    <div [ngClass]="{
                        'has-error-mobile':
                        personalDataForm.get('email').invalid && !personalDataValid
                    }" class="form-input">
                        <label for="email">Email</label>
                        <input [(ngModel)]="contactData.email"
                               [readOnly]="formSending"
                               appClearIcon
                               class="form-control"
                               formControlName="email"
                               id="email"
                               placeholder="Enter your email"
                               type="text">
                        <span [class.d-none]="formSending" class="xIcon"></span>
                    </div>

                    <div [ngClass]="{
                        'has-error-mobile':
                        personalDataForm.get('phone').invalid && !personalDataValid
                    }" class="form-input">
                        <label for="phone">Phone</label>
                        <input [(ngModel)]="contactData.phone"
                               [readOnly]="formSending"
                               [textMask]="{ mask: phoneMask }"
                               appClearIcon
                               class="form-control"
                               formControlName="phone"
                               id="phone"
                               placeholder="Enter your phone number"
                               type="text"
                               (input)="onPhoneNumberChange($event)"
                               #phoneElement>
                        <span [class.d-none]="formSending" class="xIcon"></span>
                    </div>
                </form>

                <div *ngIf="isMultiCity" class="list-box">
                    <li>
                        <div class="flight-info">
                            <div class="flight-info-name">
                                <small>Flight #1</small>
                                <span *ngIf="hasDateValue(formValue.departDate) || hasDirectionValue(formValue.flyingFrom, formValue.flyingTo)">
                                    Depart: {{getDateValue(formValue.departDate)}} {{extractTextFromBrackets(formValue.flyingFrom, formValue.flyingTo)}}
                                </span>
<!--                                <span>Return: 12/12 ROM-SFO</span>-->
                                <ng-container *ngTemplateOutlet="optionsTemplate"></ng-container>
                            </div>
<!--                            <i class="icon-delete"></i>-->
                        </div>
                    </li>
                    <li>
                        <div class="flight-info">
                            <div class="flight-info-name">
                                <small>Flight #2</small>
                                <span *ngIf="hasDateValue(formValue.departDate1) || hasDirectionValue(formValue.flyingFrom1, formValue.flyingTo1)">
                                    Depart: {{getDateValue(formValue.departDate1)}} {{extractTextFromBrackets(formValue.flyingFrom1, formValue.flyingTo1)}}
                                </span>
<!--                                <span>Return: 12/12 ROM-SFO</span>-->
                                <ng-container *ngTemplateOutlet="optionsTemplate"></ng-container>
                            </div>
<!--                            <i class="icon-delete"></i>-->
                        </div>
                    </li>
                    <li *ngIf="showThirdFlight">
                        <div class="flight-info">
                            <div class="flight-info-name">
                                <small>Flight #3</small>
                                <span *ngIf="hasDateValue(formValue.departDate2) || hasDirectionValue(formValue.flyingFrom2, formValue.flyingTo2)">
                                    Depart: {{getDateValue(formValue.departDate2)}} {{extractTextFromBrackets(formValue.flyingFrom2, formValue.flyingTo2)}}
                                </span>
<!--                                <span>Return: 12/12 ROM-SFO</span>-->
                                <ng-container *ngTemplateOutlet="optionsTemplate"></ng-container>
                            </div>
<!--                            <i class="icon-delete"></i>-->
                        </div>
                    </li>
<!--                    // todo show months/days like (01/01) but not as (1/1) -->
                    <!--                    <li>-->
                    <!--                        <div class="add-flight">-->
                    <!--                            <span>➕ Add another flight</span>-->
                    <!--                        </div>-->
                    <!--                    </li>-->
                </div>
                <div *ngIf="!isMultiCity" class="list-box">
                    <li>
                        <div class="flight-info">
                            <div class="flight-info-name">
                                <small>Flight</small>
                                <span *ngIf="hasDateValue(formValue.departDate) || hasDirectionValue(formValue.flyingFrom, formValue.flyingTo)">
                                    Depart: {{getDateValue(formValue.departDate)}} {{extractTextFromBrackets(formValue.flyingFrom, formValue.flyingTo)}}
                                </span>
                                <span *ngIf="!isOneWayForm && (hasDateValue(formValue.returnDate) || hasDirectionValue(formValue.flyingFrom, formValue.flyingTo))">
                                    Return: {{getDateValue(formValue.returnDate)}} {{extractTextFromBrackets(formValue.flyingTo, formValue.flyingFrom)}}
                                </span>
                                <ng-container *ngTemplateOutlet="optionsTemplate"></ng-container>
                            </div>
                        </div>
                    </li>
                </div>

                <!--                <div class="justify-content-start bundle">-->
                <!--                    <div class="d-inline-block">-->
                <!--                        <label class="form-check form-check-inline"> Add a Hotel <input class="form-check-input" id="hotelCheckbox" type="checkbox"><i class="checkmark"></i></label>-->
                <!--                        <label class="form-check form-check-inline"> Add a Cruise <input class="form-check-input" id="cruiseCheckbox" type="checkbox"><i class="checkmark"></i></label>-->
                <!--                    </div>-->
                <!--                </div>-->

            </div>

            <div class="modal-footer">
                <button (click)="getQuote()" [disabled]="formSending || !isCorrectFlight" class="btn" type="submit"> Get Quote</button>
                <app-navigation-rounds *ngIf="!isMultiCity" [activeItem]="3"></app-navigation-rounds>
            </div>

        </div>
    </div>
</div>

<ng-template #optionsTemplate>
    <span>
        {{
        flightOptions.cabin == "PREMIUMECO"
            ? "Premium Economy"
            : capitalize(flightOptions.cabin) + ' Class'
        }}, {{ flightOptions.travelers }}
        {{ flightOptions.travelers > 1 ? "Travelers" : "Traveler" }}
    </span>
</ng-template>
