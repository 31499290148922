/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./layout/common/header/header.component.ngfactory";
import * as i3 from "./layout/common/header/header.service";
import * as i4 from "@angular/common/http";
import * as i5 from "./layout/common/header/header.component";
import * as i6 from "@angular/material/dialog";
import * as i7 from "../storage/universal.inject";
import * as i8 from "@angular/platform-browser";
import * as i9 from "@angular/router";
import * as i10 from "../shared/shared-data.service";
import * as i11 from "./services/tracking.service";
import * as i12 from "./layout/common/footer/footer.component.ngfactory";
import * as i13 from "./layout/common/footer/footer.component";
import * as i14 from "ng-lazyload-image";
import * as i15 from "./app.component";
import * as i16 from "@angular/common";
import * as i17 from "./services/notify.service";
import * as i18 from "./services/tag.service";
import * as i19 from "@ng-bootstrap/ng-bootstrap";
import * as i20 from "ngx-cookie-service";
import * as i21 from "ngx-device-detector";
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
export function View_AppComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-header", [], null, [[null, "mouseleave"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mouseleave" === en)) {
        var pd_0 = (_co.onMouseLeave($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_HeaderComponent_0, i2.RenderType_HeaderComponent)), i1.ɵprd(512, null, i3.HeaderService, i3.HeaderService, [i4.HttpClient]), i1.ɵdid(2, 4308992, null, 0, i5.HeaderComponent, [i6.MatDialog, i7.AppStorage, i4.HttpClient, i8.TransferState, i3.HeaderService, i9.Router, i10.DataService, i11.TrackingService], null, null), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "main-block"], ["id", "main-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(5, 212992, null, 0, i9.RouterOutlet, [i9.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵeld(6, 0, null, null, 1, "app-footer", [], null, null, null, i12.View_FooterComponent_0, i12.RenderType_FooterComponent)), i1.ɵdid(7, 114688, null, 0, i13.FooterComponent, [i9.Router, i1.PLATFORM_ID], null, null), (_l()(), i1.ɵeld(8, 0, null, null, 2, "div", [["id", "spinner"]], [[8, "hidden", 0]], null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "img", [], null, null, null, null, null)), i1.ɵdid(10, 1720320, null, 0, i14.LazyLoadImageDirective, [i1.ElementRef, i1.NgZone, [2, "options"]], { lazyImage: [0, "lazyImage"] }, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 2, 0); _ck(_v, 5, 0); _ck(_v, 7, 0); var currVal_1 = (_co.cdnPath + "/assets/img/loader.gif"); _ck(_v, 10, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.showSpinner; _ck(_v, 8, 0, currVal_0); }); }
export function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-root", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i1.ɵdid(1, 245760, null, 0, i15.AppComponent, [i1.PLATFORM_ID, i9.Router, i16.LocationStrategy, i10.DataService, i17.NotifyService, i18.TagService, i4.HttpClient, i8.Meta, i19.NgbModal, i20.CookieService, i21.DeviceDetectorService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppComponentNgFactory = i1.ɵccf("app-root", i15.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
