import {Inject, Injectable, PLATFORM_ID, NgZone} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {isPlatformServer} from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class TagService {

  constructor(private router: Router,
              @Inject(PLATFORM_ID) private platformId,
            private zone: NgZone) {
    if (isPlatformServer(this.platformId)) {
      return;
    }

    const gtag = (<any> window).gtag;
    const bing = (<any> window).bing;

    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.zone.runOutsideAngular(() => {
          /* Google Analytics pageview event */
          gtag('config', 'G-W94VGEBXGX', { page_path: event.urlAfterRedirects });
          /* Google AdWords */
          gtag('config', 'AW-1061651272');
          console.debug('Visiting ' + event.urlAfterRedirects + ' should be tracked via GTag');
          /* Bing Ads */
          bing();
        });
      }
    });
  }
}
