import {AfterViewInit, Component, HostListener, Inject, OnDestroy, OnInit, PLATFORM_ID, ViewChild} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators
} from '@angular/forms';
import { NgbDateParserFormatter, NgbDateStruct, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { DeviceDetectorService } from "ngx-device-detector";
import isNil from 'lodash-es/isNil';
import eq from 'lodash-es/eq';
import capitalize from 'lodash-es/capitalize';
import isEmpty from 'lodash-es/isEmpty';
import includes from 'lodash-es/includes';
import isNull from 'lodash-es/isNull';
import isEqual from 'lodash-es/isEqual';
import replace from 'lodash-es/replace';
import isUndefined from 'lodash-es/isUndefined';
import split from 'lodash-es/split';
import each from 'lodash-es/each';
import clone from 'lodash-es/clone';
import filter from 'lodash-es/filter';
import remove from 'lodash-es/remove';
import trim from 'lodash-es/trim';
import {CookieService} from 'ngx-cookie-service';
import {ActivatedRoute, Router} from '@angular/router';
import {MatDialog} from '@angular/material';
import {isPlatformBrowser} from '@angular/common';
import * as moment from 'moment';
import { BehaviorSubject, interval, of, Subject, Subscription } from 'rxjs';
import {makeStateKey, TransferState} from '@angular/platform-browser';
import * as _ from 'lodash';
import * as atob from 'atob';

import {Flight} from '../../../model/flight';
import {ContactData} from '../../../model/contact-data';
import {HowItWorksDialogComponent} from '../../modal/how-it-works-dialog/how-it-works-dialog.component';
import {DataService} from '../../../../shared/shared-data.service';
import {NgbDateCustomParserFormatter} from '../../../model/ngb-date-custom-parser-formatter';
import {AppStorage} from '../../../../storage/universal.inject';
import { debounceTime, first, map, switchMap, takeUntil, takeWhile } from 'rxjs/operators';
import {ConstantService} from '../../../services/constant.service';
import {TrackingEvent, TrackingService} from '../../../services/tracking.service';
import {ApplicationEventBroadcaster} from '../../../../shared/application.event.broadcaster';
import {NotifyService} from '../../../services/notify.service';
import {getElementById} from '../../../../shared/util/DOM-util';
import {environment} from '../../../../environments/environment';
import {SearchboxService} from '../searchbox/searchbox.service';
import {QueryFlightParams} from '../../../model/query-flight-params';
import { FlightMobileInterface, FlightOptions } from '../../../model/flight-mobile.interface';
import { FlightOptionsDialogComponent } from '../../modal/flight-options-dialog/flight-options-dialog.component';
import { SearchboxFlightsMobileService } from '../../../services/searchbox-flights-mobile.service';
import { transformToRoundBrackets, transformToSquareBrackets } from '../../../model/transform-brackets';
import {
  FlyingCombinedDirectionDialogComponent
} from '../../modal/flying-combined-direction-dialog/flying-combined-direction-dialog.component';
import { RoundTripDateDialogComponent } from '../../modal/round-trip-date-dialog/round-trip-date-dialog.component';
import { FinalContactDialogComponent } from '../../modal/final-contact-dialog/final-contact-dialog.component';
import {
  DepartReturnDateDialogComponent
} from '../../modal/depart-return-date-dialog/depart-return-date-dialog.component';
import {
  GetQuoteSuccessDialogComponent
} from '../../modal/get-quote-success-dialog/get-quote-success-dialog.component';
import { GetQuoteCompleteDialogComponent } from "../../modal/get-quote-complete-dialog/get-quote-complete-dialog.component";
import { ReCaptchaV3Service } from "ng-recaptcha";
import { tripTypesEnum } from '../../../model/trip-types.enum';
import { MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";
declare let GetCustomEvent: Function;
export function setTimeout$(cb: () => void, timer?: number) {
  of(true).pipe(debounceTime(timer ? timer : 0), first()).subscribe(cb);
}

// declare let fbq: Function;

const PHONE_KEY = makeStateKey('phone');
const PHONE_VISIBLE_KEY = makeStateKey('phoneVisible');


@Component({
  selector: 'app-searchbox-new-generation',
  templateUrl: './searchbox-new-generation.component.html',
  styleUrls: ['./searchbox-new-generation.component.scss'],
  providers: [
    SearchboxService,
    {
      provide: NgbDateParserFormatter,
      useClass: NgbDateCustomParserFormatter
    }
  ]
})
export class SearchboxNewGenerationComponent implements OnInit, AfterViewInit, OnDestroy {


  @ViewChild('phoneSelect', { static: false }) phoneSelect;
  queryPanelForm: FormGroup;
  roundTripForm: FormGroup;
  oneWayForm: FormGroup;
  multiCityForm: FormGroup;
  activeForm: FormGroup = this.roundTripForm;

  /* Flight params */
  tripContent: boolean;
  cabinContent: boolean;
  tripTypeContent: boolean;
  passengersContent: boolean;
  queryFlightParams = new QueryFlightParams;
  flight: Flight = new Flight;
  flight1: Flight = new Flight;
  flight2: Flight = new Flight;
  returnDate: NgbDateStruct;

  /* Datepicker variables*/
  maxDate: NgbDateStruct;
  minDateDepart: NgbDateStruct;
  minDateReturn: NgbDateStruct;
  minDateDepart1: NgbDateStruct;
  minDateDepart2: NgbDateStruct;
  firstDayOfWeek: number;
  displayMonths: number;
  navigation: string;

  normalFlight: boolean;
  isLastFlightNormal: boolean;
  submitDisabled: boolean;
  submitBtnText: string;
  activeDepart: boolean;
  activeDepart1: boolean;
  activeDepart2: boolean;
  activeReturn: boolean;

  /* Autocomplete data*/
  filteredFromData;
  filteredFromOneWayData;
  filteredFromMultiCityData;
  filteredToData;
  filteredToOneWayData;
  filteredToMultiCityData;
  filteredFrom1MultiCityData;
  filteredTo1MultiCityData;
  filteredFrom2MultiCityData;
  filteredTo2MultiCityData;
  locationData: any[] = [];

  searchFormValid: boolean;
  queryPanelValid: boolean;
  formSending = false;
  contactData: ContactData = new ContactData();
  phoneMask = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

  showAddFlightButton = true;
  showThirdFlight = false;

  focusOut = false;
  mustSubmit = false;
  phoneVisible = false;
  subscription: Subscription;
  phone: string;
  initFlightDataNow: boolean;
  lastFlight: any;
  clickTripType = true;
  flightSubmitted = false;
  cdnPath: string;
  showSearchbox = false;
  public isMobileOrTablet: boolean;
  public flightMobileQuoteData: FlightMobileInterface;
  public bundleSave: { addCruise: boolean, addHotel: boolean } = {
    addCruise: null,
    addHotel: null,
  };
  public chosenFlyingDirections = {
    roundTripForm: {
      flyingFrom: '',
      flyingTo: ''
    },
    oneWayForm: {
      flyingFrom: '',
      flyingTo: ''
    },
    multiCityForm: {
      flyingFrom: '',
      flyingTo: '',
      flyingFrom1: '',
      flyingTo1: '',
      flyingFrom2: '',
      flyingTo2: ''
    }
  };
  public tripTypesEnum = tripTypesEnum;
  private isNormalFlightSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);

  protected destroy$: Subject<void> = new Subject<void>();

  constructor(private formBuilder: FormBuilder,
              private searchboxService: SearchboxService,
              private cookieService: CookieService,
              private route: ActivatedRoute,
              public dialog: MatDialog,
              @Inject(PLATFORM_ID) private platformId,
              private router: Router,
              private dataService: DataService,
              private state: TransferState,
              private constantService: ConstantService,
              @Inject(AppStorage) private appStorage: Storage,
              private trackingService: TrackingService,
              private broadcaster: ApplicationEventBroadcaster,
              private notifyService: NotifyService,
              private deviceService: DeviceDetectorService,
              private modalService: NgbModal,
              private searchboxFlightsMobileService: SearchboxFlightsMobileService,
              private recaptchaV3Service: ReCaptchaV3Service) {
    this.cdnPath = environment.cdnPath;
    this.flightMobileQuoteData = this.searchboxFlightsMobileService.getFlightQuoteData();
  }

  ngOnInit() {
    this.dataService.sendData({type: 'showSpinner', data: false});
    this.initPhone();
    this.isMobileOrTablet = this.deviceService.isMobile() || this.deviceService.isTablet();
    this.normalFlight = true;
    this.isNormalFlightSubject.next(true);
    this.isLastFlightNormal = true;
    this.searchFormValid = true;
    this.queryPanelValid = true;
    this.initTripContent();
    this.queryFlightParams.tripType = 'Round';
    this.submitBtnText = 'Get Quote';
    this.initForms();
    // this.initFields();
    this.initAutocompleteForAll();
  }

  routeTo(path: string): void {
    setTimeout(() => { this.router.navigateByUrl(path); console.log('Test ' + path); } , 0);
  }

  get header(): string {
    return isNil(this.constantService.getConstant('_searchBlockHeader')) ? undefined : this.constantService.getConstant('_searchBlockHeader').replace('Business', this.landingClass);
  }

  get landingClass() {
    return isNil(this.router) || isNil(this.router.url) || !this.router.url.includes('first-class') ? 'Business' : 'First';
  }

  private autoFillFromParam(name: string): void {
    if (isNil(name) || isEmpty(name)) {
      return;
    }

    this.searchboxService.getLocations(name.replace('-', ' ')).subscribe((response:any[]) => {
      if (response.length !== 0) {
        this.flight.to = response[0];
      }
    }, error => {
      //    do nothing
    });
  }

  private initPhone() {
    if (!isNil(this.state.get(PHONE_KEY, null as any))) {
      this.phone = this.state.get(PHONE_KEY, null as any);
    }
    if (!isNil(this.state.get(PHONE_VISIBLE_KEY, null as any))) {
      this.phoneVisible = this.state.get(PHONE_VISIBLE_KEY, null as any);
    }
    this.subscription = this.dataService.getData()
        .subscribe(sharedData => {
          if (eq(sharedData.type, 'phone')) {
            this.phone = sharedData.data;
          }
          if (eq(sharedData.type, 'phoneVisible')) {
            this.phoneVisible = sharedData.data;
          }
          if (eq(sharedData.type, 'autoFillTo')) {
            this.autoFillFromParam(sharedData.data);
          }
        });
  }

  private initTripContent() {
    this.queryFlightParams.cabin = 'BUSINESS';
    if (!isEmpty(this.appStorage.getItem('WF_CABIN_TYPE'))) {
      this.queryFlightParams.cabin = this.appStorage.getItem('WF_CABIN_TYPE');
    }
    this.queryFlightParams.passengers = 1;
    if (!isEmpty(this.appStorage.getItem('WF_ADULT'))) {
      this.queryFlightParams.passengers = Number(this.appStorage.getItem('WF_ADULT'));
    }
  }

  /* Start init autocomplete */
  private initAutocompleteForAll() {
    /* Init autocomplete for round trip */
    this.filteredFromData = this.initAutocomplete(this.roundTripForm, 'flyingFrom');
    this.filteredToData = this.initAutocomplete(this.roundTripForm, 'flyingTo');
    /* Init autocomplete for one way */
    this.filteredFromOneWayData = this.initAutocomplete(this.oneWayForm, 'flyingFrom');
    this.filteredToOneWayData = this.initAutocomplete(this.oneWayForm, 'flyingTo');
    /* Init autocomplete for multi city */
    this.filteredFromMultiCityData = this.initAutocomplete(this.multiCityForm, 'flyingFrom');
    this.filteredToMultiCityData = this.initAutocomplete(this.multiCityForm, 'flyingTo');
    this.filteredFrom1MultiCityData = this.initAutocomplete(this.multiCityForm, 'flyingFrom1');
    this.filteredTo1MultiCityData = this.initAutocomplete(this.multiCityForm, 'flyingTo1');
    this.filteredFrom2MultiCityData = this.initAutocomplete(this.multiCityForm, 'flyingFrom2');
    this.filteredTo2MultiCityData = this.initAutocomplete(this.multiCityForm, 'flyingTo2');
  }

  private initAutocomplete(form: FormGroup, contrl: string) {
    return form.get(contrl).valueChanges
        .pipe(
            debounceTime(200),
            switchMap(value => {
                  if (!this.mustSubmit && !isEmpty(value) && isNil(value.match(/\D+\(\w+\)/i))) {
                    return this.searchboxService.getLocations(value.trim())
                        .pipe(
                            map((response: any[]) => {
                                  if (response.length === 0 && !isNull(value) && !isEmpty(value)) {
                                    return Array.of('No cities found');
                                  } else {
                                    return response;
                                  }
                                }
                            ));
                  } else {
                    return [];
                  }
                }
            ));
  }

  /* End init autocomplete */

  private initForms() {
    this.initRoundTripForm();
    this.initOneWayForm();
    this.initMultiCityForm();
    this.initQueryPanelForm();
    this.activeForm = this.roundTripForm;
  }

  private initRoundTripForm() {
    this.roundTripForm = this.formBuilder.group({
      flyingFrom: ['', [Validators.required, this.noWhitespaceValidator]],
      flyingTo: ['', [Validators.required, this.noWhitespaceValidator]],
      departDate: ['', Validators.required],
      returnDate: ['', Validators.required]
    });
    this.roundTripForm.valueChanges
        .pipe(debounceTime(100))
        .subscribe(data => {
          if (this.roundTripForm.valid && this.mustSubmit) {
            this.submitSearch();
          }
        });
  }

  private initOneWayForm() {
    this.oneWayForm = this.formBuilder.group({
      flyingFrom: ['', [Validators.required, this.noWhitespaceValidator]],
      flyingTo: ['', [Validators.required, this.noWhitespaceValidator]],
      departDate: ['', Validators.required]
    });
    this.oneWayForm.valueChanges
        .pipe(debounceTime(100))
        .subscribe(data => {
          if (this.oneWayForm.valid && this.mustSubmit) {
            this.submitSearch();
          }
        });
  }

  private initMultiCityForm() {
    this.multiCityForm = this.formBuilder.group({
      flyingFrom: ['', [Validators.required, this.noWhitespaceValidator]],
      flyingTo: ['', [Validators.required, this.noWhitespaceValidator]],
      flyingFrom1: ['', [Validators.required, this.noWhitespaceValidator]],
      flyingTo1: ['', [Validators.required, this.noWhitespaceValidator]],
      flyingFrom2: [''],
      flyingTo2: [''],
      departDate: ['', Validators.required],
      departDate1: ['', Validators.required],
      departDate2: ['']
    });
    this.multiCityForm.valueChanges
        .pipe(debounceTime(100))
        .subscribe(data => {
          if (this.multiCityForm.valid && this.thirdFlightValid() && this.mustSubmit) {
            this.submitSearch();
          }
        });
  }

  private thirdFlightValid() {
    return this.showThirdFlight
        ? this.showThirdFlight
        && !isEmpty(this.multiCityForm.get('flyingFrom2').value)
        && !isEmpty(this.multiCityForm.get('flyingTo2').value)
        && !isEmpty(this.multiCityForm.get('departDate2').value)
        : true;
  }

  changeMustSubmit(mustSubmit: boolean) {
    this.mustSubmit = mustSubmit;
  }

  private initQueryPanelForm() {
    this.queryPanelForm = this.formBuilder.group({
      firstName: ['', Validators.compose([Validators.required, this.noWhitespaceValidator])],
      email: ['', Validators.compose([Validators.required, Validators.email])],
      phone: ['', Validators.compose([
          Validators.required,
          Validators.pattern('^[(]\\d{3}[)][\\s]\\d{3}[-]\\d{4}$'),
          this.phoneValidator()])]
    });
  }

  private phoneValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (this.phoneSelect && !_.isEmpty(control.value) && control.value.startsWith('(1')
          && this.phoneSelect.getCountryData().iso2 === 'us') {
        return { phoneFormatError: true };
      } else {
        return null;
      }
    };
  }

  private initDatepicker() {
    this.firstDayOfWeek = 7;
    const width = (window.innerWidth > 0) ? window.innerWidth : screen.width;
    this.displayMonths = width >= 768 ? 2 : 1;
    this.navigation = 'arrows';
    const now: Date = new Date();
    this.maxDate = this.prepareDate(moment(now).add(3, 'y').toDate());
    this.minDateDepart = this.prepareDate(now);
    if (this.isEmptyDate(this.flight.departDate)) {
      this.minDateReturn = this.prepareDate(moment(now).add(1, 'd').toDate());
      this.minDateDepart1 = this.prepareDate(moment(now).add(1, 'd').toDate());
      this.minDateDepart2 = this.prepareDate(moment(now).add(1, 'd').toDate());
    }
  }

  initFields() {
    this.searchboxService.getInitQueryFlightParams().subscribe(
        response => {
          this.queryFlightParams = response;
          if (eq(response.cabin, 'BLANK')) {
            this.queryFlightParams.cabin = 'BUSINESS';
          }
          this.flight.from = response.flights[0].from;
          this.flight.to = response.flights[0].to;

          this.chosenFlyingDirections.roundTripForm.flyingFrom = response.flights[0].from;
          this.chosenFlyingDirections.roundTripForm.flyingTo = response.flights[0].to;
          this.chosenFlyingDirections.oneWayForm.flyingFrom = response.flights[0].from;
          this.chosenFlyingDirections.oneWayForm.flyingTo = response.flights[0].to;
          this.chosenFlyingDirections.multiCityForm.flyingFrom = response.flights[0].from;
          this.chosenFlyingDirections.multiCityForm.flyingTo = response.flights[0].to;

          this.flight.departDate = this.prepareDate(moment(response.flights[0].departDate).toDate());
          this.returnDate = this.prepareDate(moment(response.returnDate).toDate());
          this.minDateReturn = this.prepareDate(moment(response.flights[0].departDate).add(1, 'days').toDate());
          if (response.flights.length > 1) {
            this.flight1.from = response.flights[1].from;
            this.flight1.to = response.flights[1].to;
            this.flight1.departDate = this.prepareDate(moment(response.flights[1].departDate).toDate());
            this.minDateDepart1 = this.prepareDate(moment(response.flights[0].departDate).toDate());

            this.chosenFlyingDirections.multiCityForm.flyingFrom1 = response.flights[1].from;
            this.chosenFlyingDirections.multiCityForm.flyingTo1 = response.flights[1].to;
          }
          if (response.flights.length === 3) {
            this.flight2.from = response.flights[2].from;
            this.flight2.to = response.flights[2].to;
            this.flight2.departDate = this.prepareDate(moment(response.flights[2].departDate).toDate());
            this.minDateDepart2 = this.prepareDate(moment(response.flights[1].departDate).toDate());

            this.chosenFlyingDirections.multiCityForm.flyingFrom2 = response.flights[2].from;
            this.chosenFlyingDirections.multiCityForm.flyingTo2 = response.flights[2].to;

            this.showThirdFlight = true;
            this.showAddFlightButton = false;
          }
          this.setActiveForm(response.tripType);
          this.initFlightDataNow = true;
          if (isPlatformBrowser(this.platformId)) {
            this.initDatepicker();
          }
        },
        error => {
          if (isPlatformBrowser(this.platformId)) {
            this.initDatepicker();
          }
        }
    );
  }

  private notEmptyFlight(flight: Flight) {
    return this.isNotEmpty(flight.from) && this.isNotEmpty(flight.to) && this.isNotEmpty(flight.departDate);
  }

  private getFlyingFieldValue(cookieName: string) {
    return this.existInCookie(cookieName) ? atob(this.appStorage.getItem(cookieName)) : '';
  }

  private getFlyingFieldDateValue(cookieName: string) {
    return this.existInCookie(cookieName) ? this.prepareDate(moment(this.appStorage.getItem(cookieName)).toDate()) : null;
  }

  private setActiveForm(tripType: any) {
    if (eq(tripType, 'Round')) {
      this.activeForm = this.roundTripForm;
    } else if (eq(tripType, 'OneWay')) {
      this.activeForm = this.oneWayForm;
    } else {
      this.activeForm = this.multiCityForm;
    }
  }

  private existInCookie(name: string): boolean {
    return !(isUndefined(this.appStorage.getItem(name)) || isNull(this.appStorage.getItem(name)) || isEmpty(this.appStorage.getItem(name)));
  }

  private prepareDate(date: Date): NgbDateStruct {
    return {
      day: date.getDate(),
      month: date.getMonth() === 12 ? 1 : date.getMonth() + 1,
      year: date.getFullYear(),
    };
  }

  private prepareCityName(fullNameAirport: string): string {
    let result: string;
    const splittedAirportName = remove(split(fullNameAirport, /[,-]/), (current) => {
      return current !== '';
    });
    const cityName = trim(splittedAirportName[splittedAirportName.length - 2]);
    result = cityName + ' (' + trim(splittedAirportName[0]) + ')';
    return result;
  }

  private isEmpty(fieldValue): boolean {
    return isEmpty(fieldValue) || isUndefined(fieldValue) || isNull(fieldValue);
  }

  private isNotEmpty(fieldValue): boolean {
    return !isEmpty(fieldValue) && !isUndefined(fieldValue) && !isNull(fieldValue);
  }

  submitSearch() {
    if (this.activeForm === this.multiCityForm && !this.thirdFlightValid()) {
      return;
    }
    if (this.activeForm.valid && isPlatformBrowser(this.platformId)) {
      this.checkDirectionValidation();
    }
  }

  private checkDirectionValidation() {
    if (this.activeForm === this.roundTripForm) {
      if (this.roundTripForm.get('flyingFrom').value === this.chosenFlyingDirections.roundTripForm.flyingFrom
          && this.roundTripForm.get('flyingTo').value === this.chosenFlyingDirections.roundTripForm.flyingTo) {
        this.submit();
      } else {
        this.normalFlight = false;
        this.isNormalFlightSubject.next(false);
      }
    } else if (this.activeForm === this.oneWayForm) {
      if (this.oneWayForm.get('flyingFrom').value === this.chosenFlyingDirections.oneWayForm.flyingFrom
          && this.oneWayForm.get('flyingTo').value === this.chosenFlyingDirections.oneWayForm.flyingTo) {
        this.submit();
      } else {
        this.normalFlight = false;
        this.isNormalFlightSubject.next(false);
      }
    } else if (this.activeForm === this.multiCityForm) {
      if (this.isCorrectMultiCityData()) {
        this.submit();
      } else {
        this.normalFlight = false;
        this.isNormalFlightSubject.next(false);
      }
    }
  }

  public isCorrectMultiCityData(): boolean {
    if (this.showThirdFlight) {
      return this.multiCityForm.get('flyingFrom').value === this.chosenFlyingDirections.multiCityForm.flyingFrom
          && this.multiCityForm.get('flyingTo').value === this.chosenFlyingDirections.multiCityForm.flyingTo
          && this.multiCityForm.get('flyingFrom1').value === this.chosenFlyingDirections.multiCityForm.flyingFrom1
          && this.multiCityForm.get('flyingTo1').value === this.chosenFlyingDirections.multiCityForm.flyingTo1
          && this.multiCityForm.get('flyingFrom2').value === this.chosenFlyingDirections.multiCityForm.flyingFrom2
          && this.multiCityForm.get('flyingTo2').value === this.chosenFlyingDirections.multiCityForm.flyingTo2;
    } else {
      return this.multiCityForm.get('flyingFrom').value === this.chosenFlyingDirections.multiCityForm.flyingFrom
          && this.multiCityForm.get('flyingTo').value === this.chosenFlyingDirections.multiCityForm.flyingTo
          && this.multiCityForm.get('flyingFrom1').value === this.chosenFlyingDirections.multiCityForm.flyingFrom1
          && this.multiCityForm.get('flyingTo1').value === this.chosenFlyingDirections.multiCityForm.flyingTo1;
    }
  }

  private modelChanged() {
    return !isEqual(this.queryFlightParams, this.lastFlight);
  }

  public noWhitespaceValidator(control: FormControl) {
    let isWhitespace = (control.value || '').trim().length === 0;
    let isValid = !isWhitespace;
    return isValid ? null : { 'isEmpty': true }
  }

  submit() {
    this.fillQueryFlightParams();
    if (!isUndefined(this.queryFlightParams.cabin) && !isUndefined(this.queryFlightParams.passengers)) {
      if (this.modelChanged() || (this.modelChanged() === false && this.normalFlight === false && this.isLastFlightNormal === true)) {
        this.formSending = true;
        this.disableButton();
        this.trackingService.getTrack().subscribe(() => {
              this.broadcaster.next(new TrackingEvent());
              this.searchboxService.submitSearch(this.queryFlightParams).subscribe(
                  response => {
                    this.formSending = false;
                    this.enableButton();
                    if (!isNil(response.data.error)) {
                      alert(response.data.error);
                    } else {
                      // We lost dial code after contact form hid, to prevent it store code before (WSF-1766)
                      // if (this.normalFlight === true && response.data.normal === false) {
                      if (this.normalFlight === true) {
                        this.contactData.cCode = this.dialCode;
                      }
                      this.normalFlight = response.data.normal;
                      this.isNormalFlightSubject.next(response.data.normal);
                      this.isLastFlightNormal = response.data.normal;
                      // restore dial code (WSF-1766)
                      setTimeout(() => {
                        this.restoreDialCode();
                      });

                      this.flightSubmitted = true;
                      this.lastFlight = clone(this.queryFlightParams);
                    }
                  },
                  error => {
                    this.formSending = false;
                    this.enableButton();
                  });
            },
            () => {
              this.formSending = false;
              this.enableButton();
              this.showWarnNoty();
            });
      }
    }
  }

  private restoreDialCode(): void {
    if (this.phoneSelect) {
      if (this.isMobileOrTablet) {
        if (!Object.keys(this.phoneSelect.getCountryData()).length) {
          this.phoneSelect.setCountry('us');
          this.phoneSelect.el.nativeElement.previousElementSibling.style.pointerEvents = 'none';
          this.contactData.cCode = '1';
        }
      } else {
        this.setCountry(this.contactData.cCode);
      }
    }
  }

  private fillQueryFlightParams() {
    this.setFlights();
  }

  private setFlights() {
    const flightCount: number = this.queryFlightParams.tripType === 'MultiCity' ?
        ((isUndefined(this.flight2.from) || isEmpty(this.flight2.from)) ? 2 : 3) : 1;
    const flights: Flight[] = new Array(flightCount);
    this.setFromTo(flights, flightCount);
    this.setDepart(flights, flightCount);
    if (this.queryFlightParams.tripType === 'Round') {
      this.setReturnDate();
    }
    this.queryFlightParams.flights = flights;
  }

  private setFromTo(flights: Flight[], flightCount: number) {
    flights[0] = new Flight;
    flights[0].from = this.flight.from;
    flights[0].to = this.flight.to;
    if (flightCount >= 2) {
      flights[1] = new Flight;
      flights[1].from = this.flight1.from;
      flights[1].to = this.flight1.to;
    }
    if (flightCount === 3) {
      flights[2] = new Flight;
      flights[2].from = this.flight2.from;
      flights[2].to = this.flight2.to;
    }
  }

  private setDepart(flights: Flight[], flightCount: number) {
    const departDate: NgbDateStruct = this.flight.departDate;
    flights[0].departDate = this.formatDate(departDate);
    if (flightCount >= 2) {
      const departDate1: NgbDateStruct = this.flight1.departDate;
      flights[1].departDate = this.formatDate(departDate1);
    }
    if (flightCount === 3) {
      const departDate2: NgbDateStruct = this.flight2.departDate;
      flights[2].departDate = this.formatDate(departDate2);
    }
  }

  private formatDate(date: NgbDateStruct): string {
    return date.month + '/' + date.day + '/' + date.year;
  }

  private setReturnDate() {
    const returnDate: NgbDateStruct = this.returnDate;
    this.queryFlightParams.returnDate = this.formatDate(returnDate);
  }

  private disableButton() {
    this.submitDisabled = true;
    this.submitBtnText = 'Loading...';
  }

  private enableButton() {
    this.submitDisabled = false;
    this.submitBtnText = 'Get Quote';
  }

  setCountry(countryCode) {
    const currentCountry = filter(document.getElementsByClassName('country'), function (country) {
      return eq(countryCode, country.getAttribute('data-dial-code'));
    });
    if (currentCountry.length > 0) {
      this.phoneSelect.setCountry(currentCountry[0].getAttribute('data-country-code'));
    }
  }

  getCountryData() {
    return this.phoneSelect.getCountryData();
  }

  changeCabinType(cabinType: string) {
    this.queryFlightParams.cabin = cabinType;
    if (this.activeForm.valid) {
      this.submitSearch();
    }
  }

  changeTripType(tripType: string, currentForm) {
    // todo make mobile changeTripType
    // todo add optimization for this code
    if (this.queryFlightParams.tripType === 'Round') {
      this.chosenFlyingDirections.oneWayForm.flyingFrom = this.chosenFlyingDirections.roundTripForm.flyingFrom;
      this.chosenFlyingDirections.oneWayForm.flyingTo = this.chosenFlyingDirections.roundTripForm.flyingTo;
      this.chosenFlyingDirections.multiCityForm.flyingFrom = this.chosenFlyingDirections.roundTripForm.flyingFrom;
      this.chosenFlyingDirections.multiCityForm.flyingTo = this.chosenFlyingDirections.roundTripForm.flyingTo;
    } else if (this.queryFlightParams.tripType === 'OneWay') {
      this.chosenFlyingDirections.roundTripForm.flyingFrom = this.chosenFlyingDirections.oneWayForm.flyingFrom;
      this.chosenFlyingDirections.roundTripForm.flyingTo = this.chosenFlyingDirections.oneWayForm.flyingTo;
      this.chosenFlyingDirections.multiCityForm.flyingFrom = this.chosenFlyingDirections.oneWayForm.flyingFrom;
      this.chosenFlyingDirections.multiCityForm.flyingTo = this.chosenFlyingDirections.oneWayForm.flyingTo;
    } else {
      this.chosenFlyingDirections.oneWayForm.flyingFrom = this.chosenFlyingDirections.multiCityForm.flyingFrom;
      this.chosenFlyingDirections.oneWayForm.flyingTo = this.chosenFlyingDirections.multiCityForm.flyingTo;
      this.chosenFlyingDirections.roundTripForm.flyingFrom = this.chosenFlyingDirections.multiCityForm.flyingFrom;
      this.chosenFlyingDirections.roundTripForm.flyingTo = this.chosenFlyingDirections.multiCityForm.flyingTo;
    }
    this.queryFlightParams.tripType = tripType;
    this.activeForm = currentForm;
    this.focusOut = false;
    this.clickTripType = true;
  }

  changeTravelers() {
    if (this.activeForm.valid) {
      this.submitSearch();
    }
  }

  /* Close Datepicker */
  onClickedOutsideDp(event, dpId, inputId) {
    if (this.mustClose(event, inputId)) {
      if (includes(inputId, 'Depart1')) {
        this.activeDepart1 = false;
      } else if (includes(inputId, 'Depart')) {
        this.activeDepart = false;
      } else {
        this.activeReturn = false;
      }
      dpId.close();
    }
  }

  private mustClose(event, inputId) {
    let inDatepicker = false;
    each(event.target.classList, className => {
      if (includes(className, 'ngb') || eq(className, 'text-muted')) {
        inDatepicker = true;
      }
    });
    if (this.isNotEmpty(this.getElementById(inputId))) {
      return !(this.getElementById(inputId).contains(event.target) || inDatepicker);
    } else {
      return false;
    }
  }

  private getElementById(elementId) {
    return document.getElementById(elementId);
  }

  private getElementByClass(elementClass) {
    return document.getElementsByClassName(elementClass);
  }

  showWarnNoty(): void {
    this.notifyService.warn('Can\'t process your request now. Please try later.', {
      closeWith: ['click', 'button'],
      timeout: 10000
    });
  }

  public saveSelectedItem(tripType: string, flyingDirection: string, event: MatAutocompleteSelectedEvent): void {
    this.chosenFlyingDirections[tripType][flyingDirection] = event.option.value;
  }

  public executeRecaptchaCheckAction(): void {
    this.recaptchaV3Service.execute('submit')
        .pipe(takeUntil(this.destroy$))
        .subscribe((token) => {
          if (token) {
            this.getQuote(token);
          }
        });
  }

  getQuote(token?: string) {
    if (this.queryPanelForm.get('phone').hasError('phoneFormatError')) {
      this.notifyService.warn('Incorrect area code', {
        closeWith: ['click', 'button'],
        timeout: 5000
      });
    }

    if (!this.activeForm.valid) {
      this.searchFormValid = false;
      this.queryPanelValid = false;
      return;
    }
    if (!this.flightSubmitted) {
      this.submitSearch();
      this.showWarnNoty();
      return;
    }
    if (this.queryPanelForm.valid) {
      this.formSending = true;
      this.disableButton();
      this.preparePhone();
      this.searchFormValid = true;
      this.queryPanelValid = true;
      if (this.bundleSave.addCruise || this.bundleSave.addHotel) {
        this.contactData.comments = `${this.bundleSave.addHotel ? '<p> Add Hotel: true </p>' : ''} ${this.bundleSave.addCruise ? '<p> Add Cruise: true </p>' : ''}`.trim()
      }
      this.contactData.recaptchaToken = token;

      this.trackingService.getTrack().subscribe(() => {
            this.searchboxService.sendRequest(this.contactData).subscribe(
                response => {
                  this.formSending = false;
                  this.enableButton();
                  if (eq(response.status, 'FAILED')) {
                    alert(response.data.details);
                  } else {
                    // Lead Track when a user expresses interest in your offering (ex. form submission,
                    // sign up for trial, landing on pricing page)
                    // fbq('track', 'Lead');

                    // Bing tracking request is implemented on your-request page
                    // GetCustomEvent();
                    this.openGetQuoteCompleteDialog();
                  }
                },
                error => {
                  console.warn('Can\'t process your request now. Please try later.');
                  this.enableButton();
                  this.formSending = false;
                });
          },
          () => {
            this.formSending = false;
            this.enableButton();
            this.showWarnNoty();
          });
    } else {
      this.queryPanelValid = false;
    }
  }

  private preparePhone() {
    this.contactData.cCode = this.dialCode;
    this.contactData.phone = replace(this.contactData.phone, new RegExp('[ ()-]', 'g'), '');
  }

  private openGetQuoteCompleteDialog(): void {
    const dialogRef = this.dialog.open(GetQuoteCompleteDialogComponent, {
      width: '570px',
      disableClose: true,
      panelClass: 'complete-dialog-container',
      autoFocus: false
    });
    dialogRef.afterClosed().subscribe(() => {
      this.router.navigateByUrl('/your-request');
    });
  }

  get dialCode(): string {
    const dialCode = this.phoneSelect.getCountryData().dialCode;
    return !isNil(dialCode) && dialCode !== '0'? dialCode: '1';
  }

  onClickedOutsideTripContent(event) {
    if (eq(event.target.className, 'BusinessClassClick') ||
        eq(event.target.className, 'fa fa-angle-down')) {
      return;
    }
    this.tripContent = false;
  }

  onClickedOutsideCabinType(event) {
    if (eq(event.target.className, 'cabinClick') ||
        eq(event.target.className, 'cabinClick ng-star-inserted') ||
        eq(event.target.className, 'cabinClick fa fa-angle-down')) {
      return;
    }
    this.cabinContent = false;
  }

  onClickedOutsideTripType(event) {
    if (eq(event.target.className, 'tripClick') ||
        eq(event.target.className, 'tripClick ng-star-inserted') ||
        eq(event.target.className, 'tripClick fa fa-angle-down')) {
      return;
    }
    this.tripTypeContent = false;
  }
  onClickedOutsidePassengers(event) {
    if (eq(event.target.className, 'passengersClick') ||
        eq(event.target.className, 'passengersClick fa fa-angle-down')) {
      return;
    }
    this.passengersContent = false;
  }

  initReturnDatepicker() {
    if (!this.isEmptyDate(this.flight.departDate)) {
      let date = new Date(this.flight.departDate.year, this.flight.departDate.month - 1, this.flight.departDate.day);
      this.minDateReturn = this.prepareDate(moment(date).add(1, 'd').toDate());
    }
  }

  initDepartDatapicker() {
    if (!this.isEmptyDate(this.flight.departDate)) {
      let date = new Date(this.flight.departDate.year, this.flight.departDate.month - 1, this.flight.departDate.day);
      this.minDateDepart1 = this.prepareDate(moment(date).toDate());
    }
    if (!this.isEmptyDate(this.flight1.departDate)) {
      let date = new Date(this.flight1.departDate.year, this.flight1.departDate.month - 1, this.flight1.departDate.day);
      this.minDateDepart2 = this.prepareDate(moment(date).toDate());
    }
  }

  openHowItWorksDialog(): void {
    this.dataService.sendData({type: 'showModal', data: true});
    const dialogRef = this.dialog.open(HowItWorksDialogComponent, {
      panelClass: 'how-it-works-dialog'
    });
    dialogRef.afterClosed().subscribe(result => {
      this.dataService.sendData({type: 'showModal', data: false});
      console.trace('The dialog was closed');
    });
  }

  @HostListener('document:click')
  handleClick() {
    if (isPlatformBrowser(this.platformId)) {
      this.fitCountrySelect();
      this.initFlightDataNow = false;
      this.clickTripType = false;
    }
  }

  fitCountrySelect() {
    const phoneElem: HTMLElement = <HTMLElement> this.getElementByClass('intl-tel-input')[0];
    if (!isNil(phoneElem)) {
      const width = phoneElem.offsetWidth;
      const countryListElem: HTMLElement = <HTMLElement> this.getElementByClass('country-list')[0];
      if (!isNil(countryListElem)) {
        countryListElem.style.width = width + 'px';
      }
    }
  }

  ngAfterViewInit(): void {
    if (this.isMobileOrTablet) {
      this.phoneSelect.el.nativeElement.previousElementSibling.style.pointerEvents = 'none';
    }
    if (isPlatformBrowser(this.platformId)) {
      setTimeout$(() => {
        this.submitSearch();
        this.fitCountrySelect();
        /* Set contact info */
        this.searchboxService.getInitContactData().subscribe(response => {
          if (!isNull(response.data.contactInfo.email)) {
            this.contactData = response.data.contactInfo;
            this.contactData.comments = '';
            const splittedPhone = split(response.data.contactInfo.phone, ' ');
            if (splittedPhone.length > 1) {
              this.contactData.phone = splittedPhone[1].replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
              this.setCountry(replace(splittedPhone[0], '+', ''));
            }
          }
        });
      });
    }
  }

  onBlur(id: string) {
    let blurredElement = getElementById(id);
    setTimeout$(() => blurredElement.blur(), 0);
  }

  addFlight(): void {
    this.showAddFlightButton = false;
    this.showThirdFlight = true;
  }

  ngOnDestroy(): void {
    // unsubscribe to ensure no memory leaks
    if (this.subscription) {
      this.subscription.unsubscribe();
    }

    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  onFocusOutAirportInput(event): void {
    if (isNil(event.relatedTarget) || (!isNil(event.relatedTarget) && !includes(event.relatedTarget.className, 'mat-option'))) {
      this.changeMustSubmit(true);
      if (this.formSending === false) {
        this.submitSearch();
      }
    }
  }

  jumpToNextControl(element, autocompleteId): void {
    if (!this.isMobileOrTablet) {
      this.changeMustSubmit(true);
      if (element && !this.initFlightDataNow && !this.clickTripType) {
        if (element instanceof HTMLInputElement) {
          this.setTimeout$(element);
        } else {
          const nativeElement = element._elRef.nativeElement;
          if (element && (includes(nativeElement.id, 'Return') || includes(nativeElement.id, 'Depart'))) {
            setTimeout$(() => {
              let currentAutocomplete = document.getElementById(autocompleteId);
              if (currentAutocomplete) {
                currentAutocomplete.blur();
              }
              element.toggle();
            }, 100);
          }
        }
      }
    }
  }

  setTimeout$(element) {
    setTimeout$(() => {
      if (this.formSending) {
        interval(10)
            .pipe(takeWhile(() => this.formSending))
            .subscribe(() => {
              setTimeout$(() => element.click(), 100);
              // This will be called every 10 milliseconds until `formSending` flag is set to false
            });
      } else {
        setTimeout$(() => element.click(), 100);
      }
    }, 100);
  }

  capitalize(value: string) {
    return capitalize(value);
  }

  get  withForm(): boolean {
    return !this.router.url.includes('faq') &&
        !this.router.url.includes('about-us') &&
        !this.router.url.includes('terms-and-conditions') &&
        !this.router.url.includes('terms-and-conditions-app') &&
        !this.router.url.includes('insurance-terms') &&
        !this.router.url.includes('privacy-policy') &&
        !this.router.url.includes('corporate') &&
        !this.router.url.includes('reviews') &&
        !this.router.url.includes('our-team') &&
        !this.router.url.includes('your-request') &&
        !this.router.url.includes('privacy-app') &&
        !this.router.url.includes('contact-us') ;
  }

  private isEmptyDate(date: NgbDateStruct) {
    return date ? isNaN(date.day) || isNaN(date.month) || isNaN(date.year) : true;
  }

  public openTripTypeMobile(): void {
    if (this.isMobileOrTablet) {
      this.openFlightOptionsDialog();
    } else {
      this.tripTypeContent = !this.tripTypeContent;
    }
  }

  public openCabinTypeMobile(): void {
    if (this.isMobileOrTablet) {
      this.openFlightOptionsDialog();
    } else {
      this.cabinContent = !this.cabinContent;
    }
  }

  public openTravelersMobile(): void {
    if (this.isMobileOrTablet) {
      this.openFlightOptionsDialog();
    } else {
      this.passengersContent = !this.passengersContent;
    }
  }

  public getControlName(c: FormControl): string | null {
    const formGroup = c.parent.controls;
    return Object.keys(formGroup).find(name => c === formGroup[name]) || null;
  }

  public openMobileDepartReturnDateDialog(control: FormControl, event: MouseEvent, isDepart: boolean = false): void {
    if (this.deviceService.isMobile() || this.deviceService.isTablet()) {
      this.dataService.sendData({type: 'showModal', data: true});
      const dialogRef: NgbModalRef = this.modalService.open(DepartReturnDateDialogComponent, {
        windowClass: 'date-depart-modal',
        backdropClass: 'hiw-backdrop'
      });

      dialogRef.componentInstance.isDepart = isDepart;
      dialogRef.componentInstance.isMultiCity = this.activeForm === this.multiCityForm;
      if (control.value) {
        dialogRef.componentInstance.selectedValue = control.value;
      }

      dialogRef.result.then((result) => {
        if (result) {
          control.setValue(result);

          if (this.activeForm === this.oneWayForm) {
            this.openContactMobileDialog(null);
          }
        }

        if (event) {
          (event.target as HTMLInputElement).blur();
        }

        this.dataService.sendData({type: 'showModal', data: false});
      }, (reason) => {
        if (this.activeForm === this.oneWayForm) {
          this.openMobileFromToDialog((this.oneWayForm.get('flyingFrom') as FormControl), (this.oneWayForm.get('flyingTo') as FormControl), null, true);
        } else if (this.activeForm === this.multiCityForm) {
          if (this.getControlName(control).includes('2')) {
            this.openMobileFromToDialog(this.multiCityForm.get('flyingFrom2') as FormControl, this.multiCityForm.get('flyingTo2') as FormControl,
              null, true, 'departDate2');
          } else if (this.getControlName(control).includes('1')) {
            this.openMobileFromToDialog(this.multiCityForm.get('flyingFrom1') as FormControl, this.multiCityForm.get('flyingTo1') as FormControl,
              null, false, 'departDate1');
          } else {
            this.openMobileFromToDialog(this.multiCityForm.get('flyingFrom') as FormControl, this.multiCityForm.get('flyingTo') as FormControl,
              null, false, 'departDate');
          }
        }
        this.dataService.sendData({type: 'showModal', data: false});
      });
    }
  }

  public openContactMobileDialog(event: MouseEvent): void {
    if (this.deviceService.isMobile() || this.deviceService.isTablet()) {
      this.dataService.sendData({type: 'showModal', data: true});
      const dialogRef: NgbModalRef = this.modalService.open(FinalContactDialogComponent, {
        windowClass: 'traveler-name-modal',
        backdropClass: 'hiw-backdrop'
      });

      dialogRef.componentInstance.searchboxContactData = {
        cCode: '1',
        firstName: this.contactData.firstName,
        email: this.contactData.email,
        phone: _.replace(this.contactData.phone, new RegExp('[ ()-]', 'g'), '')
      };

      dialogRef.componentInstance.searchboxActiveForm = this.activeForm;
      dialogRef.componentInstance.isMultiCity = this.activeForm === this.multiCityForm;
      dialogRef.componentInstance.isOneWayForm = this.activeForm === this.oneWayForm;
      dialogRef.componentInstance.isMultiCityValid = this.activeForm === this.multiCityForm && this.thirdFlightValid();
      dialogRef.componentInstance.showThirdFlight = this.showThirdFlight;
      dialogRef.componentInstance.isNormalFlight$ = this.isNormalFlightSubject.asObservable();
      dialogRef.componentInstance.flightOptions = {
        travelers: this.queryFlightParams.passengers,
        cabin: this.queryFlightParams.cabin
      };

      dialogRef.result.then((result) => {
        if (event) {
          (event.target as HTMLInputElement).blur();
        }

        this.dataService.sendData({type: 'showModal', data: false});
        Object.keys(result.contactData).forEach((item: string) => {
          if (!_.isNil(result.contactData[item])) {
            this.contactData[item] = result.contactData[item];
          }
        });
        if (this.contactData.phone && this.contactData.phone.length > 1) {
          this.contactData.phone = this.contactData.phone.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
        }

        if (result.isSuccess) {
          this.openFinalSuccessDialog();
        }

        if (result.moveBack) {
          if (this.activeForm === this.roundTripForm) {
            this.openMobileRoundTripDateDialog((this.roundTripForm.get('departDate') as FormControl), null);
          } else if (this.activeForm === this.oneWayForm) {
            this.openMobileDepartReturnDateDialog((this.oneWayForm.get('departDate') as FormControl), null, true);
          }
        }
      }, (reason) => {
        this.dataService.sendData({type: 'showModal', data: false});
      });
    }
  }

  public openMobileRoundTripDateDialog(control: FormControl, event: MouseEvent, isDepart: boolean = false): void {
    if (this.deviceService.isMobile() || this.deviceService.isTablet()) {
      this.dataService.sendData({type: 'showModal', data: true});
      const dialogRef: NgbModalRef = this.modalService.open(RoundTripDateDialogComponent, {
        windowClass: 'date-depart-modal',
        backdropClass: 'hiw-backdrop'
      });

      dialogRef.componentInstance.isDepart = isDepart;
      if (control.value) {
        dialogRef.componentInstance.selectedValue = control.value;
      }

      if (this.roundTripForm.get('departDate').value && this.roundTripForm.get('departDate').value.year) {
        dialogRef.componentInstance.startDay = this.roundTripForm.get('departDate').value;
      }

      if (this.roundTripForm.get('returnDate').value && this.roundTripForm.get('returnDate').value.year) {
        dialogRef.componentInstance.endDay = this.roundTripForm.get('returnDate').value;
      }

      dialogRef.result.then((result) => {
        if (result) {
          this.roundTripForm.get('departDate').setValue(result.startDay);
          this.roundTripForm.get('returnDate').setValue(result.endDay);
        }

        if (event) {
          (event.target as HTMLInputElement).blur();
        }

        this.dataService.sendData({type: 'showModal', data: false});

        if (result) {
          this.openContactMobileDialog(null);
        }
      }, (reason) => {
        this.dataService.sendData({type: 'showModal', data: false});
        this.openMobileFromToDialog((this.roundTripForm.get('flyingFrom') as FormControl), (this.roundTripForm.get('flyingTo') as FormControl), null, true);
      });
    }
  }

  private getFormGroupName(): string {
    if (this.activeForm === this.roundTripForm) {
      return this.tripTypesEnum.Round;
    } else if (this.activeForm === this.oneWayForm) {
      return this.tripTypesEnum.OneWay;
    } else {
      return this.tripTypesEnum.MultiCity;
    }
  }

  // MAIN DIALOG
  public openMobileFromToDialog(fromControl: FormControl, toControl: FormControl, event: MouseEvent, isFlyingFrom: boolean = false, nextStepControlName: string = null): void {
    if (this.deviceService.isMobile() || this.deviceService.isTablet()) {
      this.dataService.sendData({type: 'showModal', data: true});
      const dialogRef: NgbModalRef = this.modalService.open(FlyingCombinedDirectionDialogComponent, {
        windowClass: 'flying-from-modal',
        backdropClass: 'hiw-backdrop'
      });
      dialogRef.componentInstance.isFlyingFrom = isFlyingFrom;
      dialogRef.componentInstance.isMultiCity = this.activeForm === this.multiCityForm;
      dialogRef.componentInstance.chosenFlyingDirections = this.chosenFlyingDirections;
      dialogRef.componentInstance.formGroupName = this.getFormGroupName();
      dialogRef.componentInstance.controlsName = { from: this.getControlName(fromControl), to: this.getControlName(toControl) };
      if (fromControl.value) dialogRef.componentInstance.formDepartureValue = transformToSquareBrackets(fromControl.value);
      if (toControl.value) dialogRef.componentInstance.formDestinationValue = transformToSquareBrackets(toControl.value);

      dialogRef.result.then((result): void => {
        if (event) (event.target as HTMLInputElement).blur();

        if (result) {
          fromControl.setValue(transformToRoundBrackets(result.from));
          toControl.setValue(transformToRoundBrackets(result.to));

          if (this.activeForm === this.roundTripForm) {
            this.openMobileRoundTripDateDialog((this.roundTripForm.get('departDate') as FormControl), null);
          } else if (this.activeForm === this.oneWayForm) {
            this.openMobileDepartReturnDateDialog((this.oneWayForm.get('departDate') as FormControl), null, true);
          } else if (this.activeForm === this.multiCityForm) {
            this.openMobileDepartReturnDateDialog((this.multiCityForm.get(nextStepControlName) as FormControl), null, true);
          }
        }
        this.dataService.sendData({type: 'showModal', data: false});
      }, (reason): void => {
        if (this.getControlName(fromControl).includes('flyingFrom1')) {
          this.openMobileDepartReturnDateDialog(this.multiCityForm.get('departDate') as FormControl, null, true);
        } else if (this.getControlName(fromControl).includes('flyingFrom2')) {
          this.openMobileDepartReturnDateDialog(this.multiCityForm.get('departDate1') as FormControl, null, true);
        }

        this.dataService.sendData({type: 'showModal', data: false});
      });
    }
  }

  public checkContactOpeningSource(event: MouseEvent): void {
    if (this.isMobileOrTablet) {
      if (this.activeForm === this.roundTripForm) {
        this.openMobileFromToDialog(this.roundTripForm.get('flyingFrom') as FormControl, this.roundTripForm.get('flyingTo') as FormControl, null, true);

        return
      } else if (this.activeForm === this.oneWayForm) {
        this.openMobileFromToDialog(this.oneWayForm.get('flyingFrom') as FormControl, this.oneWayForm.get('flyingTo') as FormControl, null, true);

        return;
      } else {
        this.openContactMobileDialog(event);
      }
    }
  }

  public countryChanged(): void {
    this.queryPanelForm.get('phone').updateValueAndValidity();
  }

  private openFlightOptionsDialog(): void {
    if (this.isMobileOrTablet) {
      this.searchboxFlightsMobileService.setFlightOptions({
        tripType: this.queryFlightParams.tripType,
        passengers: this.queryFlightParams.passengers,
        cabin: this.queryFlightParams.cabin
      });
      this.dataService.sendData({type: 'showModal', data: true});
      const dialogRef = this.modalService.open(FlightOptionsDialogComponent, {
        windowClass: 'date-return-modal',
        backdropClass: 'hiw-backdrop'
      });

      dialogRef.result.then((result) => {
        const data: FlightOptions = this.flightMobileQuoteData.queryCruiseParams;
        this.queryFlightParams.cabin = data.cabin;
        this.queryFlightParams.tripType = data.tripType;
        this.queryFlightParams.passengers = data.passengers;
        this.focusOut = false;
        this.clickTripType = true;

        if (this.queryFlightParams.tripType === 'Round') {
          this.activeForm = this.roundTripForm;
        } else if (this.queryFlightParams.tripType === 'OneWay') {
          this.activeForm = this.oneWayForm;
        } else if (this.queryFlightParams.tripType === 'MultiCity') {
          this.activeForm = this.multiCityForm;
        }

        if (this.activeForm.valid) {
          this.submitSearch();
        }
        this.dataService.sendData({type: 'showModal', data: false});
      }, (reason) => {
        this.dataService.sendData({type: 'showModal', data: false});
      });
    }
  }

  private openFinalSuccessDialog(): void {
    if (this.deviceService.isMobile() || this.deviceService.isTablet()) {
      this.dataService.sendData({type: 'showModal', data: true});
      const dialogRef = this.modalService.open(GetQuoteSuccessDialogComponent, {
        windowClass: 'traveler-phone-modal',
        backdropClass: 'hiw-backdrop'
      }).result.then((result) => {
        this.dataService.sendData({type: 'showModal', data: false});
      }, (reason) => {
        this.dataService.sendData({type: 'showModal', data: false});
      });
    }
  }
}
